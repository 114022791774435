import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { User } from 'src/app/shared/models/user';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { UserChangedData } from '../../shared/models/user';
import { UserService } from '../../shared/services/user/user.service';
import {
  logout,
  logoutCompletely,
} from './../authentication/authentication.actions';
import {
  handleHttpError,
  setGlobalError,
  setGlobalLanguage,
  showSnackbarMessage,
} from './../global/global.actions';
import * as UserActions from './user.actions';
import { WebsocketService } from 'src/app/shared/services/websocket/websocket.service';
import { globalFeature } from '../global/global.state';
import { usersFeature } from './user.state';

@Injectable()
export class UserEffects {
  private readonly actions$ = inject(Actions);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly transloco = inject(TranslocoService);
  private readonly userService = inject(UserService);
  private readonly utilsService = inject(UtilsService);
  private readonly websockets = inject(WebsocketService);

  removeUserLocally$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.removeUserLocally),
        mergeMap(() => {
          localStorage.removeItem(`user`);
          return EMPTY;
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  fetchConsumerOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchConsumerOrganisation),
      switchMap(() =>
        this.userService.getConsumerOrganisation().pipe(
          map((simpleConsumer) =>
            UserActions.setUnauthConsumer({ simpleConsumer }),
          ),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUser),
      switchMap(() =>
        this.userService.getUser().pipe(
          tap((user) => {
            const userLocation = user?.location_detail;
            if (
              userLocation?.real_time_orders === true ||
              (!userLocation && user.organisation.real_time_orders === true)
            ) {
              this.store.dispatch(UserActions.connectWebSocket());
            }
          }),
          mergeMap((user) => [UserActions.setUser({ payload: user })]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  mergeCustomTranslations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setUser),
        filter(({ payload }) => !!payload),
        map(({ payload: user }) => {
          if (!user?.organisation) return;
          const keys = Object.keys(user.organisation).filter((key) =>
            key.startsWith(`custom_translations_`),
          );
          keys?.forEach((key) => {
            const lang = key.split('_')?.pop();
            this.transloco.setTranslation(user.organisation[key], lang, {
              merge: true,
            });
          });
        }),
      ),
    { dispatch: false },
  );

  fetchUserAndRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserAndRedirect),
      switchMap(({ route, delay }) =>
        this.userService.getUser().pipe(
          mergeMap((user) => {
            setTimeout(() => {
              this.router.navigate(route);
            }, delay ?? 0);
            return [UserActions.setUser({ payload: user })];
          }),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  saveUserLocally$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.saveUserLocally),
        switchMap(({ user }) => {
          if (user) {
            localStorage.setItem('user', JSON.stringify(user));
          } else {
            localStorage.removeItem('user');
          }
          return EMPTY;
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  restoreFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.restoreUserLocally),
      switchMap(() => {
        try {
          const user = JSON.parse(localStorage.getItem('user') ?? '') as User;
          return [UserActions.setUser({ payload: user })];
        } catch {
          return [];
        }
      }),
      catchError(() => EMPTY),
    ),
  );

  onUnauthorized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleHttpError),
      switchMap(({ error }) => {
        if ((error as HttpErrorResponse).status === 401) {
          return [logout()];
        } else {
          return [];
        }
      }),
    ),
  );

  changeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.patchUser),
      withLatestFrom(
        this.store.pipe(
          select(usersFeature.selectCurrentUser),
          filter((user) => !!user),
          map((user) => user.url),
        ),
      ),
      switchMap(
        ([
          {
            payload: { data, successMessage, snackClass, formId },
          },
          url,
        ]) =>
          this.utilsService
            .patch<Partial<UserChangedData>, User>(url, data)
            .pipe(
              mergeMap((payload) => {
                const actions: Action[] = [UserActions.setUser({ payload })];
                if (successMessage) {
                  actions.push(
                    showSnackbarMessage({
                      message: successMessage,
                      snackClass,
                    }),
                  );
                }
                return actions;
              }),
              catchError((error: unknown) => [
                handleHttpError({ error, formId }),
              ]),
            ),
      ),
    ),
  );

  changeUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePassword),
      switchMap(({ payload: { data, formId } }) =>
        this.userService.changePassword(data).pipe(
          mergeMap((res) => [showSnackbarMessage({ message: res.detail })]),
          catchError((error: unknown) => [handleHttpError({ error, formId })]),
        ),
      ),
    ),
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setUser),
      withLatestFrom(this.store.select(globalFeature.selectLanguage)),
      switchMap(([{ payload: user }, lang]) => {
        const actions: Action[] = [UserActions.saveUserLocally({ user })];
        if (user?.settings?.language && user?.settings?.language !== lang) {
          actions.push(
            setGlobalLanguage({ payload: user?.settings?.language }),
          );
        }
        return actions;
      }),
    ),
  );

  patchUserLang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setGlobalLanguage),
      withLatestFrom(this.store.select(usersFeature.selectCurrentUser)),
      switchMap(([{ payload: lang }, user]) => {
        if (user && !user?.terminal && user?.settings?.language !== lang) {
          return [
            UserActions.patchUser({
              payload: { data: { settings: { language: lang } } },
            }),
          ];
        }
        return [];
      }),
    ),
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteAccount),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([, user]) =>
        this.utilsService.delete(user?.url ?? '').pipe(
          mergeMap(() => [
            showSnackbarMessage({ message: this.deleteSuccessMsg }),
            logoutCompletely(),
          ]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  connectWebSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.connectWebSocket),
        withLatestFrom(this.store.select(usersFeature.selectCurrentUser)),
        switchMap(([, user]) => {
          if (user)
            this.websockets.connect(`${user.organisation.identifier}_tickets`);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  disconnectWebSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.disconnectWebSocket),
        switchMap(() => {
          this.websockets.disconnect();
          return [];
        }),
      ),
    { dispatch: false },
  );

  reportWebSocketError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.reportWebSocketError),
      switchMap(() => [
        setGlobalError({
          error: this.transloco.translate('shared.errors.websockets-error'),
        }),
      ]),
    ),
  );

  private deleteSuccessMsg: string;

  constructor() {
    this.deleteSuccessMsg = '';
    this.transloco
      .selectTranslate(`settings.delete-account.success`)
      .subscribe((msg: string) => (this.deleteSuccessMsg = msg));
  }
}
