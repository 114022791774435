import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, Input, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ActivatedRoute,
  DefaultUrlSerializer,
  ParamMap,
  Params,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { format } from 'date-fns';
import { filter, take } from 'rxjs/operators';
import {
  isManagableAccess,
  isManagableItem,
  ManagableAccess,
} from 'src/app/shared/models/consumers';

import { setAuthKeyAndId } from '../store/authentication/authentication.actions';
import { LANGUAGES } from './constants/languages';
import { SimpleAuthenticationParams } from './models/authentication';
import {
  ManagableItemParams,
  MenusParams,
  SelectFieldModel,
  UnknownManagableItem,
} from './models/misc';
import { getParamsFromManagableOrder, isManagableOrder } from './models/orders';
import { BaseModel, FormErrors } from './models/globals';
import { usersFeature } from '../store/user/user.state';
import { DietDetailShort } from './models/diets';
import { SimpleAuthenticationIds } from './constants/misc';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: '',
  styles: [''],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HighlightOnError {
  control: FormControl;

  @Input() set errors(val: Record<string, string>) {
    if (val?.[this.errorKey()] && this.control) {
      this.control.setErrors({ error: true });
      this.control.markAsTouched();
    }
  }
  readonly errorKey = input<string>();
}

function checkForAuthenticationParams(
  params: Params,
): SimpleAuthenticationParams | undefined {
  if (params.key && SimpleAuthenticationIds.some((id) => id in params)) {
    return Object.entries(params).reduce((acc, [key, value]) => {
      if (key === 'key') return { ...acc, key: value };
      return { ...acc, id: { key, value } };
    }, {}) as SimpleAuthenticationParams;
  }
  return undefined;
}

export function checkAuthenticationQueryParamsFactory(
  _route: ActivatedRoute,
  store: Store,
  _http: HttpClient,
) {
  return () => {
    const params = new DefaultUrlSerializer().parse(
      window.location.search,
    ).queryParams;
    return new Promise<void>((resolve) => {
      const parsedParams = checkForAuthenticationParams(params);
      if (parsedParams) {
        store.dispatch(setAuthKeyAndId({ payload: parsedParams }));
        store
          .pipe(select(usersFeature.selectSimpleConsumer))
          .pipe(
            filter((v) => !!v),
            take(1),
          )
          .subscribe(() => resolve());
      } else {
        resolve();
      }
    });
  };
}

function flattenArrayObject(obj: any[], name?: string): Record<string, any> {
  return obj.reduce((acc: Record<string, any>, curr: any, index: number) => {
    const newName = name ? `${name}_${index}` : index.toString();
    if (curr !== obj && (Array.isArray(curr) || curr instanceof Object)) {
      return {
        ...acc,
        ...flattenObject(curr, newName),
      };
    }
    acc[newName] = curr;
    return acc;
  }, {});
}

function flattenDictObject(obj: object, name?: string): Record<string, any> {
  return Object.entries(obj).reduce((acc, curr) => {
    const newName = name ? `${name}_${curr[0]}` : curr[0];
    if (
      curr[1] !== obj &&
      (Array.isArray(curr[1]) || curr[1] instanceof Object)
    ) {
      return {
        ...acc,
        ...flattenObject(curr[1], newName),
      };
    }
    if (name) {
      acc[`${name}_${curr[0]}`] = curr[1];
    } else {
      acc[curr[0]] = curr[1];
    }
    return acc;
  }, {});
}

export function flattenObject(
  obj: object | any[],
  name?: string,
): Record<string, any> {
  if (Array.isArray(obj)) return flattenArrayObject(obj, name);
  if (obj instanceof Object) return flattenDictObject(obj, name);
  return obj;
}

export type MakeValuesAny<T> = {
  [P in keyof T]: any;
};

export type TypeVariants<
  U extends PropertyKey,
  T extends Record<U, unknown>,
> = {
  [P in U]: { option: P; payload?: T[P] };
}[U];

export function filterLang(lang?: string): keyof typeof LANGUAGES {
  if (lang && Object.keys(LANGUAGES).includes(lang)) return lang as LANGUAGES;
  return LANGUAGES.en;
}

export function extractFormErrors(errors: object): FormErrors {
  if (Array.isArray(errors)) return { error: errors[0] };
  return Object.entries(errors).reduce(
    (acc: FormErrors, curr: [string, string[] | string]) => {
      if (Array.isArray(curr[1])) {
        acc[curr[0]] = curr[1][0]; // error is an array, so we use first element of the array as error message
      } else {
        acc[curr[0]] = curr[1]; // error is a string, so we simply add it to the accumulated object
      }
      return acc;
    },
    {},
  );
}

/**
 * Reverses sorting option by adding or removing minus sign at the beginning.
 * For example: -created_at becomes created_at and vice versa.
 * @param sortingOption
 * @returns reversed sorting option
 */
export function reverseSorting(sortingOption: string): string {
  return sortingOption.startsWith(`-`)
    ? sortingOption.substring(1, sortingOption.length)
    : `-${sortingOption}`;
}

/**
 * Checks if requested path leads to other than /orders page
 * @param url
 * @returns true if URL leads to other than /orders page, false otherwise
 */
export function showLeaveDialog(url: string): boolean {
  if (!url) return false;
  return !/orders\/.+/.test(url);
}

export function isOrders(url: string): boolean {
  return url && /orders\//.test(url);
}

/**
 * Format native Date object to format yyyy-MM-dd (e.g. 2020-09-30)
 * @param date - new Date() is used instead if this parameter is omitted
 */
export function getFormattedDate(date?: Date): string {
  return format(date ?? new Date(), 'yyyy-MM-dd');
}

/**
 * Constructs request parameters for the /orders or /menus endpoint after a user clicked a button in the /manage/accesses or /manage/orders table
 * @param item - ManagableAccess or ManagableOrder that corresponds to the choosen row of the /manage/accesses or /manage/orers table
 */
export function getParamsFromManagableItem(
  item: UnknownManagableItem,
): ManagableItemParams | undefined {
  if (item && isManagableItem(item)) {
    if (isManagableAccess(item)) {
      return getParamsFromManagableAccess(item);
    } else if (isManagableOrder(item)) {
      return getParamsFromManagableOrder(item);
    }
  }
  return undefined;
}

function getParamsFromManagableAccess(
  access: ManagableAccess,
): ManagableItemParams {
  return access.is_user ? { created_by: access.id } : { consumer: access.id };
}

export function getMenuParamsFromManagableAccess(
  access: ManagableAccess,
): MenusParams {
  return getParamsFromManagableItem(access) as MenusParams;
}

export function getMenusParamsFromQueryParams(map: ParamMap): MenusParams {
  const keys: (keyof MenusParams)[] = [`location`, `created_by`, `consumer`];
  return populateMenusParams(map, keys);
}

export function getOrdersParamsFromQueryParams(map: ParamMap): MenusParams {
  const keys: (keyof MenusParams)[] = [`created_by`, `consumer`];
  return populateMenusParams(map, keys);
}

function populateMenusParams(
  map: ParamMap,
  keys: (keyof MenusParams)[],
): MenusParams {
  const params: MenusParams = {};
  keys.forEach((key) => {
    if (map.has(key) && map.get(key) !== '') {
      params[key] = +map.get(key);
    }
  });
  return params;
}

/**
 * Returns true if the given date is the same as the birthday
 * @param birthday the birthday date
 * @param date the date to compare with the birthday
 * @returns whether the given date is the same as the birthday
 */
export function hasBirthday(birthday: string, date: string): boolean {
  if (!birthday || !date) return false;
  return (
    new Date(birthday).getMonth() === new Date(date).getMonth() &&
    new Date(birthday).getDate() === new Date(date).getDate()
  );
}

export function parseDietsDetail(
  diets: string[] | DietDetailShort[],
): string[] {
  if (!diets?.length) return [];
  return Array.isArray(diets) && typeof diets[0] === 'string'
    ? (diets as string[])
    : (diets as DietDetailShort[]).map((d) => d.diet_name);
}

export function mapBaseModelToSelectFieldModel(
  items: BaseModel[],
  lang: keyof typeof LANGUAGES,
  useIdentifier = false,
): SelectFieldModel[] {
  return (
    items?.map((item) => ({
      id: useIdentifier ? item.identifier : item.id,
      name: item[lang]
        ? item[lang]
        : (item[Object.keys(LANGUAGES).find((l) => item[l])] as string),
      location: item.location,
    })) ?? []
  );
}

/**
 * Remove undefined, null (and NaN) values from an object
 * @param obj - params object
 */
export function removeUndefinedAndNull<T extends object>(
  obj: T,
  removeEmptyString = true,
): Partial<T> {
  return Object.entries(obj)
    .filter(
      ([, value]) =>
        !(
          value === undefined ||
          value === null ||
          Number.isNaN(value) ||
          (removeEmptyString && value === '')
        ),
    )
    .reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {} as Partial<T>,
    );
}

export function isObjectEmpty(obj?: object): boolean {
  return !obj || !Object.keys(obj).length;
}

/**
 * Download file response
 */
export function downloadFile(res: HttpResponse<Blob>) {
  const data = res.body;
  const filename =
    res.headers.get('content-disposition')?.split('"')[1]?.trim() ??
    'menutech-download.pdf';
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.download = filename;

  const url = URL.createObjectURL(data);
  a.href = url;
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    a.remove();
  }, 100);
}

/**
 * Opens file response in new tab
 */
export function openBlobNewTab(res: HttpResponse<Blob>) {
  const url = URL.createObjectURL(res.body);
  window.open(url, '_blank')?.focus();
  window.URL.revokeObjectURL(url);
}
