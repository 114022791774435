import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { getBrowserLang } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationServiceBuilder } from 'src/app/shared/services/authentication/authentication.builder';

import { logoutCompletely } from '../../../store/authentication/authentication.actions';
import { TOKEN_KEY } from '../authentication/authentication.service';
import { SimpleAuthenticationParams } from '../../models/authentication';
import { showSnackbarMessage } from './../../../store/global/global.actions';
import { globalFeature } from 'src/app/store/global/global.state';
import { authenticationFeature } from 'src/app/store/authentication/authentication.state';

/**
 * Function to clone the request and set the headers
 * @param req original request to clone
 * @param lang the language to set in the headers
 * @param simpleAuth simple authentication params if set in the url (else undefined)
 * @returns clone of the request with the headers set
 */
function cloneRequest(
  req: HttpRequest<unknown>,
  lang: string,
  simpleAuth: SimpleAuthenticationParams,
): HttpRequest<unknown> {
  const token = localStorage.getItem(TOKEN_KEY);
  // NOTE: Ignore the cache for ALL request
  req = req.clone({
    setHeaders: {
      'Accept-Language': lang,
      'ngsw-bypass': 'true',
    },
  });

  if (token && req?.responseType !== 'text') {
    return req.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  if (simpleAuth) {
    let params = req.params;
    params = params.append('key', simpleAuth.key);
    params = params.append(simpleAuth.id.key, simpleAuth.id.value);
    if (params.has('own')) {
      params = params.delete('own');
    }
    return req.clone({
      params,
    });
  }

  return req;
}

/**
 * Function to intercept the http requests and add the headers, refresh the token if needed and handle errors
 * @param req original request to clone
 * @param next the next handler
 * @returns the next handler with the headers set
 */
export const authInterceptor: HttpInterceptorFn = (req, next) => {
  let isRefreshing = false;
  let lang = getBrowserLang();
  const refreshTokenSubject: BehaviorSubject<unknown> =
    new BehaviorSubject<unknown>(null);
  let simpleAuth: SimpleAuthenticationParams;

  const authServiceBuilder = inject(AuthenticationServiceBuilder);
  const store = inject(Store);

  store
    .pipe(
      select(globalFeature.selectLanguage),
      take(1),
      filter((l) => !!l),
    )
    .subscribe((l) => (lang = l));

  store
    .pipe(select(authenticationFeature.selectAuthKeyAndId), take(1))
    .subscribe((val) => (simpleAuth = val));

  const handleServerError = (error: unknown): void => {
    if (
      error instanceof HttpErrorResponse &&
      [0, 500, 502].includes(error?.status)
    ) {
      store.dispatch(showSnackbarMessage({ errorCode: error?.status }));
    }
  };

  const handle401Error = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
    refreshToken: string,
  ) => {
    if (isRefreshing) {
      return refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return next(cloneRequest(req, lang, simpleAuth));
        }),
      );
    }

    isRefreshing = true;
    refreshTokenSubject.next(null);
    return authServiceBuilder
      .getService()
      .refreshToken(refreshToken)
      .pipe(
        switchMap(({ access, refresh }) => {
          authServiceBuilder.getService().saveAccessToken(access, refresh);
          isRefreshing = false;
          refreshTokenSubject.next(refresh);
          return next(cloneRequest(req, lang, simpleAuth)).pipe(
            catchError((error: unknown) => {
              handleServerError(error);
              // eslint-disable-next-line sonarjs/no-nested-functions
              return throwError(() => new HttpErrorResponse(error));
            }),
          );
        }),
        catchError((error: unknown) => {
          if ((error as HttpErrorResponse)?.status === 401) {
            store.dispatch(logoutCompletely());
            return of(null);
          }
          return throwError(() => new HttpErrorResponse({ error }));
        }),
      );
  };

  // If the request is for the api.menutech.com, we don't need to refresh the token
  if (req.url.indexOf('api.menutech.com') !== -1) {
    req = req.clone({
      setHeaders: {
        'Accept-Language': lang,
        'ngsw-bypass': 'true',
      },
    });
    return next(req).pipe(
      catchError((error: unknown) => {
        handleServerError(error);
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  // Regular requests to the ordertaking API need to refresh the token if a 401 error is returned
  return next(cloneRequest(req, lang, simpleAuth)).pipe(
    catchError((error: unknown) => {
      // If the error is a 401, we refresh the token
      if (error instanceof HttpErrorResponse && error?.status === 401) {
        if (req.url.endsWith(`/refresh/`)) {
          handleServerError(error);
          return throwError(() => new HttpErrorResponse(error));
        }
        const refreshToken = authServiceBuilder.getService().getRefreshToken();

        if (refreshToken) return handle401Error(req, next, refreshToken);

        // If there is no refresh token, we logout the user
        store.dispatch(logoutCompletely());
        return of(null);
      }

      // If the error is a 500, 502 or 0, we show a snackbar message
      handleServerError(error);
      return throwError(() => new HttpErrorResponse(error));
    }),
  );
};
