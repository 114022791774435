import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { fetchDiets } from 'src/app/store/diets/diets.actions';
import { fetchMenu, fetchMenus } from 'src/app/store/menus/menus.actions';
import { menusFeature } from 'src/app/store/menus/menus.state';
import {
  fetchManagableItem,
  setManagableItem,
} from 'src/app/store/orders/orders.actions';
import { ordersFeature } from 'src/app/store/orders/orders.state';
import { usersFeature } from 'src/app/store/user/user.state';
import { FetchMenusParams } from 'src/app/shared/models/menus';
import { ManagableItem } from 'src/app/shared/models/misc';
import {
  getOrdersParamsFromQueryParams,
  isObjectEmpty,
} from 'src/app/shared/utils.functions';

export const ordersResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,

  _state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);

  const managableItem = router.getCurrentNavigation().extras
    ?.state as ManagableItem;
  const queryParams = getOrdersParamsFromQueryParams(route.queryParamMap);
  store.dispatch(setManagableItem({ item: managableItem || undefined }));
  if (isObjectEmpty(managableItem) && !isObjectEmpty(queryParams)) {
    store.dispatch(fetchManagableItem(queryParams));
  }
  const { menuId, date } = route.params as Record<string, string>;
  if (!menuId || !date) {
    router.navigate([`menus`]);
    return of(false);
  }
  return store.pipe(
    select(menusFeature.selectCurrentMenu),
    withLatestFrom(
      store.select(menusFeature.selectMenus),
      store.select(usersFeature.selectIsManagerOrAgent),
      store.select(ordersFeature.selectSource),
    ),
    switchMap(([menu, menus, isManagerOrAgent, _menuSource]) => {
      if (isManagerOrAgent) store.dispatch(fetchDiets());
      if (!menu || (menu.date && menu.date !== date)) {
        store.dispatch(
          fetchMenu({ params: { menu: menuId, date, ...queryParams } }),
        );
      }

      if (!menus) {
        const fetchMenusKeys: Partial<keyof FetchMenusParams>[] = [
          'consumer',
          'created_by',
        ];
        const fetchMenusParams: FetchMenusParams = Object.fromEntries(
          Object.entries(queryParams).filter(([key]) =>
            fetchMenusKeys.includes(key as any),
          ),
        );
        store.dispatch(
          fetchMenus({
            params: isObjectEmpty(fetchMenusParams)
              ? { own: true }
              : fetchMenusParams,
          }),
        );
      }
      return of(true);
    }),
    take(1),
  );
};
