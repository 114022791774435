import { dietOrdering } from '../constants/misc';
import { BaseModel } from './globals';

export type Diet = BaseModel;

export interface DietDetailShort {
  identifier: string;
  diet_name: string;
  info_url: string;
}
export interface DietParams {
  ordering?: (typeof dietOrdering)[number];
  pagination?: 'off';
}

export const TEXTURE_LEVELS = [1, 2, 3, 4, 5] as const;

export const CONSISTENCY_LEVELS = [1, 2, 3, 4] as const;
