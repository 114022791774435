import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
  viewChild,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { asyncScheduler } from 'rxjs';
import {
  filter,
  map,
  observeOn,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { selectQueryParams } from 'src/app/store/router/router.selectors';
import { Consumer, ManagableAccess } from 'src/app/shared/models/consumers';
import { Course, Menu } from 'src/app/shared/models/menus';
import {
  OrganisationFeatures,
  Report,
  ReportFormat,
  ReportType,
  RoleChoice,
  UnauthConsumer,
  User,
} from 'src/app/shared/models/user';
import { getFormattedDate } from 'src/app/shared/utils.functions';

import { UnknownManagableItem } from '../../../shared/models/misc';
import { logout } from './../../../store/authentication/authentication.actions';
import {
  menuSelected,
  patchCheckoutDate,
  setMenus,
} from './../../../store/menus/menus.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MenusComponent } from './menus/menus.component';
import { BuildNewSkillsComponent } from './build-new-skills/build-new-skills.component';
import {
  deleteCourse,
  setSelectedCourse,
} from 'src/app/store/courses/courses.actions';
import { coursesFeature } from 'src/app/store/courses/courses.state';
import { globalFeature } from 'src/app/store/global/global.state';
import { offlineModeFeature } from 'src/app/store/offline-mode/offline-mode.state';
import { menusFeature } from 'src/app/store/menus/menus.state';
import { ordersFeature } from 'src/app/store/orders/orders.state';
import { usersFeature } from 'src/app/store/user/user.state';
import { authenticationFeature } from 'src/app/store/authentication/authentication.state';
import { reportsFeature } from 'src/app/store/reports/report.state';
import { ReportActions } from 'src/app/store/reports/report.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { DownloadReportComponent } from 'src/app/shared/components/download-report/download-report.component';

type Navigation = 'orders' | 'accesses' | 'consumers';

@Component({
  selector: 'win-menus-container',
  templateUrl: './menus.container.html',
  styleUrls: ['./menus.container.scss'],
  imports: [
    AsyncPipe,
    BuildNewSkillsComponent,
    MenusComponent,
    MatDatepickerModule,
    TranslocoPipe,
    DownloadReportComponent,
  ],
})
export class MenusContainer implements OnInit, OnDestroy {
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  menus$ = this.store.pipe(select(menusFeature.selectMenus)).pipe(
    tap((menus: Menu[]) => {
      if (!this.managableItem || !('checkin' in this.managableItem)) {
        const menuCheckin = menus?.filter((m) => m.checkin_day);
        const menuCheckout = menus?.filter((m) => m.checkout_day);
        if (menuCheckin?.length) {
          this.minDate = new Date(menuCheckin[0].date);
        }
        if (menuCheckout?.length) {
          this.maxDate = new Date(menuCheckout[0].date);
        }
      }
    }),
  );
  dailyMenus$ = this.store.pipe(select(menusFeature.selectDailyMenus));
  specialMenus$ = this.store.pipe(select(menusFeature.selectSpecialMenus));
  emailVerified$ = this.store.pipe(select(usersFeature.selectEmailVerified));
  globalLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));
  isSimpleAuth$ = this.store.pipe(
    select(authenticationFeature.selectIsSimpleAuth),
  );
  isConsumer$ = this.store.pipe(select(usersFeature.selectIsConsumer));
  isKdsUser$ = this.store.pipe(select(usersFeature.selectIsKdsUser));
  isServiceUser$ = this.store.pipe(select(usersFeature.selectIsServiceUser));
  isOffline$ = this.store.select(offlineModeFeature.selectValue);
  isAgent$ = this.store.pipe(select(usersFeature.selectIsAgent));
  isManager$ = this.store.pipe(
    select(usersFeature.selectIsManager),
    tap((isManager) => {
      this.isManager = isManager;
    }),
  );
  isTerminal$ = this.store.pipe(select(usersFeature.selectIsTerminal));
  offlineDate$ = this.store.pipe(
    select(offlineModeFeature.selectDate),
    tap((date: string) => {
      if (date) {
        this.minDate = new Date(date);
        this.maxDate = new Date(date);
      }
    }),
  );
  userFullName$ = this.store.pipe(select(usersFeature.selectUserFullName));
  selectUserStatusOrderUntil$ = this.store.pipe(
    select(usersFeature.selectUserStatusOrderUntil),
  );
  selectUser$ = this.store.pipe(select(usersFeature.selectCurrentUser));
  terminalConsumer$ = this.store.pipe(
    select(usersFeature.selectCurrentConsumer),
    tap((consumer) => {
      this.terminalConsumer = consumer;
    }),
  );
  simpleConsumer$ = this.store.pipe(
    select(usersFeature.selectSimpleConsumer),
    tap((consumer) => {
      this.simpleConsumer = consumer;
    }),
  );
  staffOrdertaking$ = this.store.pipe(
    select(usersFeature.selectFeature(OrganisationFeatures.staff_ordertaking)),
  );
  showThirdCard$ = this.store.pipe(
    select(menusFeature.selectShowThirdMenuCard),
  );
  managableItem$ = this.store.pipe(
    select(ordersFeature.selectRouterExtras),
    tap((item) => {
      this.managableItem = item;
      if (item && 'checkin' in item) {
        const mItem = item as ManagableAccess;
        if (mItem.checkin) this.minDate = new Date(mItem.checkin);
      }
      if (item && 'checkout' in item) {
        const mItem = item as ManagableAccess;
        if (mItem.checkout) this.maxDate = new Date(mItem.checkout);
      }
    }),
  );
  backQueryParam$ = this.store.pipe(
    select(selectQueryParams),
    map((params) => params?.back as Navigation),
    filter((back) => !!back),
    tap((back: Navigation) => (this.goBackPage = back)),
  );
  selectShowConsumerInfo$ = this.store.pipe(
    select(ordersFeature.selectShowConsumerInfo),
  );
  selectShowConsumerInfoDetail$ = this.store.pipe(
    select(ordersFeature.selectShowConsumerInfoDetail),
  );
  reports$ = this.store.pipe(
    select(reportsFeature.selectReportByType(ReportType.PERSONAL)),
  );

  courses$ = this.store.pipe(
    select(coursesFeature.selectCoursesEntities),
    withLatestFrom(this.globalLanguage$),
    map(([courses, lang]) =>
      courses.filter((course) => !!course[`url_${lang}`]),
    ),
  );
  loadingCourses$ = this.store.pipe(select(coursesFeature.selectLoading));

  currentUser: User;
  isManager: boolean;
  DATE_FORMAT = 'yyyy-MM-dd';
  managableItem: UnknownManagableItem;
  maxDate: Date;
  minDate = new Date();
  orderUntil: number;
  goBackPage: 'accesses' | 'consumers' | 'orders';
  simpleConsumer: UnauthConsumer;
  terminalConsumer: Consumer;
  selectedDateHasOrders: string[] = [];

  readonly datepickerInput = viewChild(MatDatepickerInput);

  readonly datepicker = viewChild(MatDatepicker);

  ngOnInit(): void {
    this.selectUserStatusOrderUntil$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: number) => {
        this.orderUntil = res;
        const date = new Date();
        date.setDate(date.getDate() + res);
        if (!this.maxDate && res) this.maxDate = date;
      });
    this.selectUser$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  dateFilter = (d: Date | null): boolean => {
    const target =
      this.managableItem ||
      this.simpleConsumer ||
      this.terminalConsumer ||
      this.currentUser;

    const canOrderPast = (this.currentUser || target).location_detail
      ? (this.currentUser || target).location_detail.allow_order_past
      : (this.currentUser || target).organisation.allow_order_past;

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const date = d || new Date();
    const maxDate = new Date(this.maxDate);
    const validDate = !isNaN(maxDate.valueOf());
    if (validDate) {
      return (
        date <= this.maxDate &&
        ((this.isManager && canOrderPast) || date >= yesterday)
      );
    } else {
      return this.isManager && canOrderPast ? true : date >= yesterday;
    }
  };

  logout(): void {
    this.store.dispatch(logout());
  }

  calculateMinDate(menu: Menu): Date {
    const target =
      this.managableItem ||
      this.simpleConsumer ||
      this.terminalConsumer ||
      this.currentUser;

    const canOrderPast = (this.currentUser || target).location_detail
      ? (this.currentUser || target).location_detail.allow_order_past
      : (this.currentUser || target).organisation.allow_order_past;
    let minDate =
      this.isManager && canOrderPast
        ? new Date(this.currentUser.organisation.date_joined)
        : new Date();
    if (
      (!this.managableItem || !this.isManager) &&
      (!this.terminalConsumer || this.currentUser.role !== RoleChoice.CONSUMER)
    ) {
      minDate = new Date(menu.can_order_from);
    } else if (target.checkin) {
      const checkin = this.dateAdapter.parse(target.checkin, this.DATE_FORMAT);
      if (checkin > minDate) minDate = checkin;
    }

    return minDate;
  }

  /**
   * Select a dated menu (redirects to the select orders page)
   * @param menu the selected menu
   */
  selectMenu(menu: Menu): void {
    this.store.dispatch(menuSelected({ menu, item: this.managableItem }));
  }

  /**
   * Select a dateless menu (opens the datepicker)
   * @param menu the selected menu
   */
  selectMenuShowDatepicker(menu: Menu): void {
    this.selectedDateHasOrders = [...menu.list_orders];
    const datepicker = this.datepicker();
    datepicker.datepickerInput.min = this.calculateMinDate(menu);
    if (menu.can_order_until)
      datepicker.datepickerInput.max = new Date(menu.can_order_until);

    datepicker.open();
    this.datepickerInput()
      .dateChange.pipe(
        tap(() => this.datepicker().close()),
        takeUntil(datepicker.closedStream),
        observeOn(asyncScheduler),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(({ value }) => {
        this.store.dispatch(
          menuSelected({
            date: getFormattedDate(value),
            menu,
            item: this.managableItem,
          }),
        );
      });
  }

  changeCheckoutDate(payload: {
    data: Partial<ManagableAccess>;
    id: number;
    url: string;
  }): void {
    this.store.dispatch(patchCheckoutDate({ payload }));
  }

  goBack(): void {
    if (this.goBackPage) {
      this.router?.navigate([`manage`, this.goBackPage]);
    }
  }

  downloadReport({
    report,
    response,
  }: {
    report: Report;
    response?: ReportFormat;
  }): void {
    this.store.dispatch(
      ReportActions.downloadReport({ report, data: { response } }),
    );
  }

  openReport(report: string): void {
    this.store.dispatch(ReportActions.viewReport({ report, params: {} }));
  }

  deleteCourse(courseId: number): void {
    this.store.dispatch(deleteCourse({ courseId }));
  }

  editCourse(course: Course): void {
    this.store.dispatch(setSelectedCourse({ course }));
  }

  addNewCourse(): void {
    this.store.dispatch(setSelectedCourse({ course: null }));
  }

  dateClass = (date: Date) => {
    if (this._findDate(this.dateAdapter.format(date, 'yyyy-MM-dd'))) {
      return ['selected'];
    }
    return [];
  };

  private _findDate(date: string): boolean {
    return this.selectedDateHasOrders.includes(date);
  }

  ngOnDestroy(): void {
    this.store.dispatch(setMenus({ menus: null }));
  }
}
