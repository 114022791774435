<header class="mat-body-strong item-header">
  <span>{{ order()?.item }}</span>
  @if (
    !isOffline() &&
    order()?.productSheet?.length > 0 &&
    !showOnlyQuantity() &&
    !showOnlyPortionSize()
  ) {
    <mat-icon
      (click)="openProductSheetModal()"
      class="info-icon clear"
      color="primary"
      [matTooltip]="'orders.descriptions.show-product-sheet' | transloco"
      matTooltipClass="win-tooltip"
      >info</mat-icon
    >
  }
  @if (isManager() || isAgent() || showOnlyQuantity()) {
    @if (quantityFieldInput()) {
      <span class="multiply-prefix">{{ ' ' }}&times;&nbsp;</span>
      <div [formGroup]="descForm" class="quantity-input">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>{{
            'orders.descriptions.quantity-placeholder' | transloco
          }}</mat-label>
          <input
            matInput
            type="number"
            formControlName="quantity"
            name="quantity"
            [attr.tabindex]="-1"
            [errorStateMatcher]="matcher"
            [min]="
              minQuantity() > quantityFieldIncrement()
                ? minQuantity()
                : quantityFieldIncrement()
            "
            [step]="quantityFieldIncrement()"
          />
          @if (
            descForm.controls.quantity.value % quantityFieldIncrement() !== 0
          ) {
            <mat-error>{{
              'orders.descriptions.quantity-error'
                | transloco: { value: quantityFieldIncrement() }
            }}</mat-error>
          }
        </mat-form-field>
      </div>
    } @else {
      <button
        mat-button
        class="update-quantity minus"
        [disabled]="
          descForm.controls.quantity.value === quantityFieldIncrement()
        "
        (click)="updateQuantity('decrease')"
      >
        -
      </button>
      <span class="quantity-value">{{ descForm.value.quantity }}</span>
      <button
        mat-button
        class="update-quantity plus"
        (click)="updateQuantity('increase')"
      >
        +
      </button>
    }
  }
  @if (showOnlyPortionSize()) {
    <span class="multiply-prefix">{{ ' ' }}</span>
    <div [formGroup]="descForm" class="quantity-input portion-size">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{
          'orders.descriptions.portion-placeholder' | transloco
        }}</mat-label>
        <mat-select
          class="portion-select"
          formControlName="portion_size"
          name="portion_size"
        >
          @for (portion of portionSizes; track portion) {
            <mat-option [attr.value]="portion" [value]="portion">{{
              'orders.descriptions.portions.' + portion * 10 | transloco
            }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  }
</header>
@if (!showOnlyQuantity() && !showOnlyPortionSize()) {
  <form class="wrapper" [formGroup]="descForm">
    @if (!showOnlyPortionSize()) {
      <div class="form-field-wrapper">
        <mat-icon>comment</mat-icon>
        <mat-form-field class="description-field" subscriptSizing="dynamic">
          <textarea
            matInput
            formControlName="description"
            name="description"
            cdkTextareaAutosize
            [attr.tabindex]="-1"
            [placeholder]="'orders.descriptions.placeholder' | transloco"
          ></textarea>
        </mat-form-field>
        @if (isManager() || isAgent()) {
          <ng-container>
            <button
              class="search-auto"
              mat-icon-button
              (click)="searchAuto(descForm.value.description); select.open()"
            >
              <mat-icon>search</mat-icon>
            </button>
            <mat-select
              class="autocomplete-select"
              color="primary"
              panelClass="panel-above"
              #select
              (selectionChange)="selectAuto($event.value)"
            >
              @if (autocomplete() === null || autocomplete() === undefined) {
                <mat-option [value]="null">{{
                  'shared.search.loading' | transloco
                }}</mat-option>
              } @else if (autocomplete().length === 0) {
                <mat-option [value]="null">{{
                  'shared.search.no-results' | transloco
                }}</mat-option>
              } @else {
                @for (auto of autocomplete(); track auto) {
                  <mat-option [value]="auto">
                    {{ auto }}
                  </mat-option>
                }
              }
            </mat-select>
          </ng-container>
        }
      </div>
    }
    @if (isManager() || isAgent() || showOnlyPortionSize()) {
      <div class="form-field-wrapper quantity">
        <mat-form-field subscriptSizing="dynamic">
          <mat-select
            class="portion-select"
            formControlName="portion_size"
            name="portion_size"
            [placeholder]="
              'orders.descriptions.portion-placeholder' | transloco
            "
          >
            @for (portion of portionSizes; track portion) {
              <mat-option [attr.value]="portion" [value]="portion">{{
                'orders.descriptions.portions.' + portion * 10 | transloco
              }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    @if (featureTexture() && (isManager() || isAgent())) {
      <div class="form-field-wrapper consistency">
        <mat-form-field subscriptSizing="dynamic">
          <mat-select
            #textureSelect
            class="texture-select"
            formControlName="texture"
            name="texture"
            [placeholder]="
              'orders.descriptions.texture-placeholder' | transloco
            "
          >
            @for (texture of textureLevels; track texture) {
              <mat-option [attr.value]="texture" [value]="texture">
                @if (texture) {
                  {{ 'orders.descriptions.texture.' + texture | transloco }}
                }
              </mat-option>
            }
          </mat-select>
          @if (textureSelect.value) {
            <button
              type="button"
              mat-icon-button
              matIconSuffix
              (click)="clearTexture($event)"
            >
              <mat-icon class="clear">clear</mat-icon>
            </button>
          }
        </mat-form-field>
      </div>
    }
    @if (featureConsistency() && (isManager() || isAgent())) {
      <div class="form-field-wrapper consistency">
        <mat-form-field subscriptSizing="dynamic">
          <mat-select
            #consistencySelect
            class="consistency-select"
            formControlName="consistency"
            name="consistency"
            [placeholder]="
              'orders.descriptions.consistency-placeholder' | transloco
            "
          >
            @for (consistency of consistencyLevels; track consistency) {
              <mat-option [attr.value]="consistency" [value]="consistency">
                @if (consistency) {
                  {{
                    'orders.descriptions.consistency.' + consistency | transloco
                  }}
                }
              </mat-option>
            }
          </mat-select>
          @if (consistencySelect.value) {
            <button
              type="button"
              mat-icon-button
              matIconSuffix
              (click)="clearConsistency($event)"
            >
              <mat-icon class="clear">clear</mat-icon>
            </button>
          }
        </mat-form-field>
      </div>
    }
  </form>
} @else if (showDescription()) {
  <form class="wrapper" [formGroup]="descForm">
    <div class="form-field-wrapper">
      <mat-icon>comment</mat-icon>
      <mat-form-field subscriptSizing="dynamic">
        <textarea
          matInput
          formControlName="description"
          name="description"
          cdkTextareaAutosize
          [attr.tabindex]="-1"
          [placeholder]="'orders.descriptions.placeholder' | transloco"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
}
