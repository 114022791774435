import { ActivatedRoute } from '@angular/router';
import { Consumer, ManagableAccess } from 'src/app/shared/models/consumers';

import { ManagableOrder } from './orders';
import { User } from './user';

export interface SelectFieldModel {
  id: number | string;
  name: string;
  location?: number;
}

export interface RouterNavigatedPayload {
  event: {
    id: number;
    url: string;
  };
  routerState: {
    root: ActivatedRoute;
    url: string;
  };
}

export interface ManagableItemParams {
  consumer?: number;
  created_by?: number;
}

export type ManagableItem = ManagableOrder | ManagableAccess;

export type FetchedManagableItem = Consumer | User;

export type UnknownManagableItem = ManagableItem | FetchedManagableItem;

export interface MenusParams extends ManagableItemParams {
  location?: number;
}

export interface RedirectParams {
  next?: string;
}

export const lightboxSelector = 'fslightbox';

export type ReportFormat = 'html' | 'json' | 'pdf' | 'excel' | 'csv';
