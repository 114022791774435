import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Menu } from 'src/app/shared/models/menus';
import { OrganisationFeatures } from 'src/app/shared/models/user';
import { usersFeature } from 'src/app/store/user/user.state';
import * as MenuActions from './menus.actions';

interface MenusState {
  menus: Menu[];
  currentMenu: Menu;
  nextMenu: Menu;
  prevMenu: Menu;
  selectedDate: string;
}

const initialState: MenusState = {
  menus: null,
  currentMenu: null,
  nextMenu: null,
  prevMenu: null,
  selectedDate: null,
};

const reducer = createReducer<MenusState>(
  initialState,
  on(MenuActions.setSelectedDate, (state, { date }) => ({
    ...state,
    selectedDate: date,
  })),
  on(MenuActions.setMenus, (state, { menus }) => {
    if (state?.currentMenu && state?.currentMenu.show_date && menus?.length) {
      const filteredMenus = menus
        ?.filter((menu) => menu.show_date)
        .sort((a, b) => {
          const dateComparison = a.date.localeCompare(b.date);
          if (dateComparison !== 0) return dateComparison;
          return a.meal_sorting - b.meal_sorting;
        });
      const currentMenuId = filteredMenus?.findIndex(
        (menu) => menu.id === state?.currentMenu?.id,
      );
      const nextMenu = filteredMenus?.[currentMenuId + 1] ?? null;
      const prevMenu = filteredMenus?.[currentMenuId - 1] ?? null;
      return {
        ...state,
        menus,
        nextMenu,
        prevMenu,
      };
    }
    return {
      ...state,
      menus,
    };
  }),
  on(MenuActions.setCurrentMenuAndRedirect, (state, { menu }) => ({
    ...state,
    currentMenu: menu,
  })),
  on(MenuActions.setCurrentMenu, (state, { menu: currentMenu }) => {
    if (currentMenu?.show_date && state.menus?.length) {
      const filteredMenus = state.menus
        ?.filter((menu) => menu.show_date)
        .sort((a, b) => {
          const dateComparison = a.date.localeCompare(b.date);
          if (dateComparison !== 0) return dateComparison;
          return a.meal_sorting - b.meal_sorting;
        });
      const currentMenuId = filteredMenus?.findIndex(
        (menu) => menu.id === currentMenu?.id,
      );
      const nextMenu = filteredMenus?.[currentMenuId + 1] ?? null;
      const prevMenu = filteredMenus?.[currentMenuId - 1] ?? null;
      return {
        ...state,
        currentMenu,
        nextMenu,
        prevMenu,
      };
    }
    return {
      ...state,
      currentMenu,
      nextMenu: null,
      prevMenu: null,
    };
  }),
  on(MenuActions.cleanCurrentMenu, (state) => ({
    ...state,
    currentMenu: undefined,
  })),
  on(MenuActions.cleanMenus, (state) => ({ ...state, menus: undefined })),
  on(MenuActions.patchCurrentMenu, (state, { id, menu: currentMenu }) => ({
    ...state,
    menus: state.menus
      ? state.menus.map((menu) => {
          if (menu.id === id) return { ...menu, ...currentMenu };
          return menu;
        })
      : null,
    currentMenu: state.currentMenu
      ? { ...state.currentMenu, ...currentMenu }
      : undefined,
  })),
  on(MenuActions.resetMenusReducer, () => initialState),
  on(MenuActions.setPrevMenu, (state, { menu: prevMenu }) => ({
    ...state,
    prevMenu,
  })),
  on(MenuActions.setNextMenu, (state, { menu: nextMenu }) => ({
    ...state,
    nextMenu,
  })),
);

export const menusFeature = createFeature({
  name: 'menus',
  reducer,
  extraSelectors: ({ selectMenus }) => ({
    selectDailyMenus: createSelector(selectMenus, (menus) =>
      menus?.filter((menu) => menu.date),
    ),
    selectSpecialMenus: createSelector(selectMenus, (menus) =>
      menus?.filter((menu) => menu.date === null),
    ),
    selectShowThirdMenuCard: createSelector(
      usersFeature.selectCurrentUser,
      usersFeature.selectIsManager,
      (user, isManager) =>
        user?.organisation?.enabled_features?.includes(
          OrganisationFeatures.staff_ordertaking,
        ) && isManager,
    ),
  }),
});
