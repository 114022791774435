import { Component, DestroyRef, OnDestroy, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  fetchAutocompleteSearch,
  showSnackbarMessage,
} from 'src/app/store/global/global.actions';
import { AnyAccessType } from 'src/app/shared/models/consumers';
import { Menu } from 'src/app/shared/models/menus';
import { OrganisationFeatures } from 'src/app/shared/models/user';

import {
  applyDefaultOrder,
  fetchProductSheet,
  fetchSource,
  ordersSelectionStateChanged,
  repeatOrdersFetched,
  sendOrdersAndNext,
  sendOrdersAndPrev,
  sendOrdersAndRedirect,
  setMenuDiets,
  setMenuLanguage,
} from 'src/app/store/orders/orders.actions';
import { ManagableItem } from '../../../shared/models/misc';
import {
  ManagableOrder,
  Order,
  SetOrdersItem,
} from '../../../shared/models/orders';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { SelectOrdersComponent } from './select-orders/select-orders.component';
import { setPrevMenu, setNextMenu } from 'src/app/store/menus/menus.actions';
import { dietsFeature } from 'src/app/store/diets/diets.state';
import { globalFeature } from 'src/app/store/global/global.state';
import { offlineModeFeature } from 'src/app/store/offline-mode/offline-mode.state';
import { menusFeature } from 'src/app/store/menus/menus.state';
import { ordersFeature } from 'src/app/store/orders/orders.state';
import { usersFeature } from 'src/app/store/user/user.state';
import { mapBaseModelToSelectFieldModel } from 'src/app/shared/utils.functions';

@Component({
  selector: 'win-orders',
  templateUrl: './orders.container.html',
  styleUrls: ['../menus/menus.container.scss', './orders.container.scss'],
  imports: [SelectOrdersComponent, AsyncPipe],
})
export class OrdersContainer implements OnDestroy {
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);
  private readonly transloco = inject(TranslocoService);

  managableItem: ManagableItem;

  currentMenu$ = this.store.pipe(select(menusFeature.selectCurrentMenu));
  selectedDate$ = this.store.pipe(select(menusFeature.selectSelectedDate));
  orders$ = this.store.pipe(select(ordersFeature.selectOrderEntities));
  repeatOrders$ = this.store.pipe(select(ordersFeature.selectRepeatOrders));
  currentMenuLanguage$ = this.store.pipe(
    select(ordersFeature.selectMenuLanguage),
  );
  showHiddenOrders$ = this.store.pipe(
    select(ordersFeature.selectShowHiddenOrders),
  );
  extraItem$ = this.store.pipe(select(ordersFeature.selectRouterExtras));
  isAgent$ = this.store.pipe(select(usersFeature.selectIsAgent));
  isManager$ = this.store.pipe(select(usersFeature.selectIsManager));
  isTerminal$ = this.store.pipe(select(usersFeature.selectTerminal));
  firstOrder$ = this.store.pipe(select(ordersFeature.selectFirstOrderOfMenu));
  featureConsistency$ = this.store.pipe(
    select(usersFeature.selectFeature(OrganisationFeatures.consistency)),
  );
  featureTexture$ = this.store.pipe(
    select(usersFeature.selectFeature(OrganisationFeatures.texture)),
  );
  prevMenu$ = this.store.pipe(select(menusFeature.selectPrevMenu));
  nextMenu$ = this.store.pipe(select(menusFeature.selectNextMenu));
  currentMenuSource$ = this.store.pipe(select(ordersFeature.selectSource));
  consumer$ = this.store.pipe(select(usersFeature.selectCurrentConsumer));
  unauthConsumer$ = this.store.pipe(select(usersFeature.selectSimpleConsumer));
  selectShowConsumerInfo$ = this.store.pipe(
    select(ordersFeature.selectShowConsumerInfo),
  );
  selectShowConsumerInfoDetail$ = this.store.pipe(
    select(ordersFeature.selectShowConsumerInfoDetail),
  );
  currentDiets$ = this.store.pipe(select(ordersFeature.selectDiets));
  saveOrderLoading$ = this.store.pipe(
    select(ordersFeature.selectSave_order_spinner),
  );
  dietsEditableField$ = this.store.pipe(select(dietsFeature.selectItems)).pipe(
    switchMap((diets) => of(diets).pipe(withLatestFrom(this.globalLanguage$))),
    map(([diets, lang]) => mapBaseModelToSelectFieldModel(diets, lang, true)),
  );
  globalLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));
  isOffline$ = this.store.pipe(select(offlineModeFeature.selectValue));
  isUserAllowOrderAllergies$ = this.store.pipe(
    select(usersFeature.selectUserAllowOrderAllergies),
  );
  isUserAllowOrderIntolerances$ = this.store.pipe(
    select(usersFeature.selectUserAllowOrderIntolerances),
  );
  showQuantity$ = this.store.select(usersFeature.selectShowQuantity);
  showPortionSize$ = this.store.select(usersFeature.selectShowPortionSize);
  currentOrders$ = this.store.pipe(
    select(ordersFeature.selectOrderEntities),
    filter((orders) => !!orders && orders.length > 0),
  );
  currentOrdersRepeat$ = this.store.pipe(
    select(ordersFeature.selectRepeatOrders),
    filter((orders) => !!orders && orders.length > 0),
  );
  selectQuantityInputField$ = this.store.pipe(
    select(usersFeature.selectQuantityInputField),
  );
  selectQuantityFieldIncrement$ = this.store.pipe(
    select(usersFeature.selectQuantityFieldIncrement),
  );

  constructor() {
    this.extraItem$
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((item: ManagableItem) => (this.managableItem = item));
  }

  sendItems(items: {
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }): void {
    this.store.dispatch(
      sendOrdersAndRedirect({
        ...items,
        editedItem: this.managableItem,
      }),
    );
  }

  sendOrdersAndNext(items: {
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }): void {
    this.store.dispatch(
      sendOrdersAndNext({
        ...items,
        editedItem: this.managableItem,
      }),
    );
  }
  sendOrdersAndPrev(items: {
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }): void {
    this.store.dispatch(
      sendOrdersAndPrev({
        ...items,
        editedItem: this.managableItem,
      }),
    );
  }

  ordersSelected(): void {
    this.store.dispatch(ordersSelectionStateChanged({ state: true }));
  }

  fetchProductSheet({ token }: { token: string }): void {
    this.store.dispatch(fetchProductSheet({ token }));
  }

  applyDefaultOrder(data: {
    consumer: AnyAccessType | ManagableOrder;
    selectedDiets: string[];
  }): void {
    this.store.dispatch(
      applyDefaultOrder({
        orderData: data.consumer,
        selectedDiets: data.selectedDiets,
      }),
    );
  }

  applyDiets(selectedDiets: string[]): void {
    this.store.dispatch(setMenuDiets({ selectedDiets }));
  }

  showUnselectSnackMessage() {
    this.store.dispatch(
      showSnackbarMessage({
        message: this.transloco.translate('manage-orders.selection-removed'),
        button: this.transloco.translate('manage-orders.undo'),
        duration: 5000,
        action: () => {
          const targets = Array.from(
            document.querySelectorAll('[data-updated=true]'),
          );
          targets.forEach((el) => {
            el.setAttribute('data-ordered', 'true');
            el.removeAttribute('data-updated');
          });
        },
      }),
    );
  }

  applyTranslation(data: { translation: string; menu: Menu }) {
    this.store.dispatch(setMenuLanguage({ language: data.translation }));
    this.store.dispatch(
      fetchSource({
        menu: data.menu,
        dietRefetch: false,
        nonRegularTranslation: data.translation,
      }),
    );
  }

  searchAutocomplete = ({ value }: { value: string }) => {
    this.store.dispatch(
      fetchAutocompleteSearch({
        field: 'description',
        value: value ?? '',
        model: 'order',
      }),
    );
  };

  clearRepeatOrders(): void {
    this.store.dispatch(
      repeatOrdersFetched({
        repeatOrders: null,
      }),
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(ordersSelectionStateChanged({ state: false }));
    this.store.dispatch(setMenuLanguage({ language: null }));
    this.store.dispatch(setPrevMenu({ menu: null }));
    this.store.dispatch(setNextMenu({ menu: null }));
  }
}
