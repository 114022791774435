import { HttpErrorResponse } from '@angular/common/http';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationServiceBuilder } from 'src/app/shared/services/authentication/authentication.builder';

import { flattenObject } from '../utils.functions';
import { setProposedEmail } from './../../store/authentication/authentication.actions';
import { EmailVerification } from './../models/authentication';
import { emailVerificationErrorFields } from '../constants/misc';

export class AsyncEmailValidator {
  static createValidator(
    authServiceBuilder: AuthenticationServiceBuilder,
    store: Store,
  ): AsyncValidatorFn {
    return (control: AbstractControl<string>): Observable<ValidationErrors> => {
      return authServiceBuilder
        .getService()
        .checkEmail(control.value)
        .pipe(
          tap((verificationResult: EmailVerification) => {
            if (verificationResult?.did_you_mean) {
              store.dispatch(
                setProposedEmail({ email: verificationResult.did_you_mean }),
              );
            }
          }),
          map((verificationResult: EmailVerification) => {
            for (const errorKey of emailVerificationErrorFields) {
              if (verificationResult[errorKey]) {
                return { [errorKey]: true };
              }
            }
            return null;
          }),
          catchError((error: unknown) => {
            if (error instanceof HttpErrorResponse) {
              if (error?.error) {
                const flatObj = flattenObject(error.error);
                const firstErrorMessage = Object.values(flatObj)[0] as string;
                if (firstErrorMessage) {
                  return of({ error: firstErrorMessage });
                }
              }
            } else {
              throw error as Error;
            }
            return of(null);
          }),
        );
    };
  }
}
