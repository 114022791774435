import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Report, ReportFormat } from 'src/app/shared/models/user';
import { ManagableOrdersParams } from 'src/app/features/managing/shared/models/managable-orders';
import { ManagableAccessesParams } from 'src/app/features/managing/shared/models/managable-accesses';

export const ReportActions = createActionGroup({
  source: 'Report/API',
  events: {
    'Fetch Reports': emptyProps(),
    'Set Reports': props<{ reports: Report[] }>(),
    'Update Report': props<{ report: Update<Report> }>(),
    'Clear Reports': emptyProps(),

    'Set Loading': props<{ loading: boolean }>(),

    'Download Report': props<{
      report: Report;
      data: (
        | Partial<ManagableAccessesParams>
        | Partial<ManagableOrdersParams>
      ) & {
        response: ReportFormat;
        columns?: string[];
      };
    }>(),
    'View Report': props<{ report: string; params: object }>(),
  },
});
