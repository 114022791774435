import { Component, input, output, signal } from '@angular/core';
import {
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { PasswordValidators } from 'ngx-validators';
import { TranslocoPipe } from '@jsverse/transloco';

import { FieldWithErrorsComponent } from 'src/app/shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from 'src/app/shared/components/form-with-errors/form-with-errors.component';

@Component({
  selector: 'win-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: [
    '../../../../shared/styles/authentication.scss',
    './confirm.component.scss',
  ],
  imports: [
    FieldWithErrorsComponent,
    FormWithErrorsComponent,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class ConfirmComponent {
  readonly spinnerState = input.required<boolean>();
  readonly confirm = output<{
    new_password1: string;
    new_password2: string;
  }>();

  showPassword1 = signal(false);
  showPassword2 = signal(false);

  passwordsForm = new FormGroup(
    {
      new_password1: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ],
      }),
      new_password2: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ],
      }),
    },
    {
      validators: [
        PasswordValidators.mismatchedPasswords(
          `new_password1`,
          `new_password2`,
        ),
      ],
    },
  );

  togglePassword1Visibility(): void {
    this.showPassword1.update((current) => !current);
  }

  togglePassword2Visibility(): void {
    this.showPassword2.update((current) => !current);
  }

  onSubmit(): void {
    // Mark all fields as touched to trigger validation
    Object.keys(this.passwordsForm.controls).forEach((key) => {
      const control = this.passwordsForm.get(key);
      control?.markAsTouched();
    });

    if (!this.passwordsForm.valid) return;

    this.confirm.emit({
      new_password1: this.passwordsForm.get('new_password1').value,
      new_password2: this.passwordsForm.get('new_password2').value,
    });
  }
}
