import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DestroyRef,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateAdapter } from '@angular/material/core';
import {
  MatCalendar,
  MatDatepicker,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { EMPTY, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  REPEAT_OPTIONS,
  RepeatOrder,
  RepeatOrderCondensed,
  RepeatOrderOptionData,
} from 'src/app/shared/models/orders';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';

@Component({
  selector: 'win-repeat-order',
  templateUrl: './repeat-order.component.html',
  styleUrls: ['./repeat-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    NgTemplateOutlet,
    TranslocoPipe,
  ],
})
export class RepeatOrderComponent implements OnChanges, OnInit {
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private readonly destroyRef = inject(DestroyRef);

  @Input() set repeatOrder(value: RepeatOrderCondensed | null) {
    this._repeatOrder = value;
    this.choosenDays = new Array(7) as boolean[];
    if (value?.repeat_weekdays?.length) {
      this.selectedOption = REPEAT_OPTIONS.WEEKDAYS;
      value.repeat_weekdays.forEach((i) => (this.choosenDays[i] = true));
    } else if (value?.repeat_dates?.length) {
      this.selectedOption = REPEAT_OPTIONS.DATES;
    } else if (value?.repeat_daily) {
      this.selectedOption = REPEAT_OPTIONS.REPEAT_DAILY;
    } else if (value?.repeat_daily_from) {
      this.selectedOption = REPEAT_OPTIONS.REPEAT_DAILY_FROM;
    } else {
      this.selectedOption = REPEAT_OPTIONS.DO_NO_REPEAT;
    }
  }
  get repeatOrder(): RepeatOrderCondensed | null {
    return this._repeatOrder;
  }
  readonly checkin = input.required<string>();
  readonly checkout = input.required<string>();
  readonly checkinTime = input<string>();
  readonly checkoutTime = input<string>();
  readonly isOffline = input.required<boolean>();
  readonly fabButton = input(false);
  readonly highlightGlobalRepeat = input(false);
  readonly repeatOrders = input<RepeatOrder[]>();
  readonly repeatOrdersAllType = input<string | null>();
  readonly repeatOrdersEquivalentToOrders = input(false);
  readonly repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom = input(false);
  readonly lang = input.required<InterfaceLanguage>();
  @Input() set preselect(value: boolean) {
    if (!value) this.selectedOption = null;
    this._preselect = value;
  }
  get preselect(): boolean {
    return this._preselect;
  }
  _preselect = true;
  readonly disabledRepeatOptions = input<REPEAT_OPTIONS[]>([]);
  readonly selectionChanged = output<RepeatOrderOptionData>();

  @ViewChild('weekMenuTrigger', { read: MatMenuTrigger })
  set weekMenu(menuTrigger: MatMenuTrigger) {
    if (!menuTrigger) return;
    const handleClick = menuTrigger._handleClick;
    menuTrigger._handleClick = (event) => {
      event.stopPropagation();
      handleClick.call(menuTrigger, event);
    };
    this._weekMenu = menuTrigger;
  }
  get weekMenu(): MatMenuTrigger | undefined {
    return this._weekMenu;
  }
  private _weekMenu: MatMenuTrigger;

  @ViewChild('picker') set picker(value: MatDatepicker<Date>) {
    this._picker = value;
    if (value) {
      value.openedStream
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.onDatepickerOpened());
    }
  }
  get picker(): MatDatepicker<Date> {
    return this._picker;
  }

  @ViewChild('dateFromPicker') set dateFromPicker(value: MatDatepicker<Date>) {
    this._dateFromPicker = value;
    if (value) {
      value.openedStream
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => EMPTY);
    }
  }
  get dateFromPicker(): MatDatepicker<Date> {
    return this._dateFromPicker;
  }

  private _dateFromPicker: MatDatepicker<Date>;
  private _picker: MatDatepicker<Date>;
  private _repeatOrder: RepeatOrderCondensed | null;
  repeatOptions = REPEAT_OPTIONS;
  selectedOption = REPEAT_OPTIONS.DO_NO_REPEAT;
  resetModel = new Date(0);
  private dateElementsMap: Record<string, HTMLElement> = {};
  private datesWrapperElem: HTMLElement;
  debouncer = new Subject<RepeatOrderOptionData>();

  options: {
    value: number;
    title?: string;
    week?: true;
    dates?: true;
    dateFrom?: true;
  }[] = [
    {
      value: 0,
      title: 'do-no-repeat',
    },
    {
      value: 1,
      title: 'repeat-daily',
    },
    {
      value: 4,
      title: 'repeat-daily-from',
      dates: true,
      dateFrom: true,
    },
    {
      value: 2,
      title: 'repeat-weekly',
      week: true,
    },
    {
      value: 3,
      title: 'specific-date',
      dates: true,
    },
  ];

  days = Array(7)
    .fill(0)
    .map((_, i) => i);

  choosenDays: boolean[] = Array(7) as boolean[];
  DATE_FORMAT = 'yyyy-MM-dd';
  selectedDates: Date[] = [];
  initDate = new Date();
  minDate: Date;
  maxDate: Date;

  ngOnChanges(changes: SimpleChanges): void {
    if ('disabledRepeatOptions' in changes) {
      this.options = this.options.filter(
        (option) => !this.disabledRepeatOptions().includes(option.value),
      );
    }
    const checkin = this.checkin();
    if ('checkin' in changes && checkin) {
      this.minDate = new Date(checkin);
    }
    const checkout = this.checkout();
    if ('checkout' in changes && checkout) {
      this.maxDate = new Date(checkout);
    }
  }

  ngOnInit() {
    this.debouncer
      .pipe(debounceTime(600), takeUntilDestroyed(this.destroyRef))
      .subscribe((data: RepeatOrderOptionData) => {
        this.selectionChanged.emit(data);
      });
  }

  optionSelected(
    event: MouseEvent,
    {
      value,
      week,
      dates,
      dateFrom,
    }: {
      value: REPEAT_OPTIONS;
      week?: true;
      dates?: true;
      dateFrom?: true;
    },
  ): void {
    if (week) {
      event.stopPropagation();
      this.selectedOption = REPEAT_OPTIONS.WEEKDAYS;
      this.weekMenu.openMenu();
      return;
    }
    if (dates && !dateFrom) {
      event.stopPropagation();
      this.selectedOption = REPEAT_OPTIONS.DATES;
      this.picker.open();
      return;
    }
    if (dates && dateFrom) {
      event.stopPropagation();
      this.selectedOption = REPEAT_OPTIONS.REPEAT_DAILY_FROM;
      this.selectedDates = [];
      this.dateFromPicker.open();
      return;
    }
    if (value === this.selectedOption) return;
    if (
      value === REPEAT_OPTIONS.DO_NO_REPEAT ||
      value === REPEAT_OPTIONS.REPEAT_DAILY
    ) {
      this.selectedOption = this._preselect ? value : value || null;
      this.selectedDates = [];
      this.choosenDays = [];
      if (value === REPEAT_OPTIONS.DO_NO_REPEAT) {
        this.selectionChanged.emit({
          option: value,
          payload: { url: this.repeatOrder?.url, id: this.repeatOrder?.id },
        });
      } else {
        this.selectionChanged.emit({ option: value });
      }
    }
  }

  handleWeekday(event: MouseEvent, day: number): void {
    event.stopPropagation();
    this.choosenDays[day] = !this.choosenDays[day];
    this.selectedDates = [];
    this.debouncer.next({
      option: REPEAT_OPTIONS.WEEKDAYS,
      payload: this.choosenDays
        .map((v, i) => (v ? i : undefined))
        .filter((v) => Number.isInteger(v)),
    });
  }

  dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      const date = event.value;
      const index = this._findDate(date);
      if (index === -1) {
        this.selectedDates.push(date);
        this.addDate(date);
      } else {
        this.selectedDates.splice(index, 1);
        this.removeDate(date);
      }
      this.sendDates();
      this.resetModel = new Date(0);
      this.calendarPreventCloseAndUpdate();
    }
  }
  dateFromChanged(event: MatDatepickerInputEvent<Date>): void {
    const date = event.value;
    this.selectionChanged.emit({
      option: REPEAT_OPTIONS.REPEAT_DAILY_FROM,
      payload: this.dateAdapter.format(date, this.DATE_FORMAT),
    });
  }

  calendarPreventCloseAndUpdate() {
    const componentRef = this.picker['_componentRef'] as ComponentRef<
      MatDatepicker<Date>
    >;
    const calendar = componentRef.instance['_calendar'] as MatCalendar<Date>;
    calendar.updateTodaysDate();

    const closeFn = this.picker.close;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.picker.close = () => {};
    setTimeout(() => {
      this.picker.close = closeFn;
    });
  }

  sendDates(): void {
    this.debouncer.next({
      option: REPEAT_OPTIONS.DATES,
      payload: [
        ...this.selectedDates.map((date) =>
          this.dateAdapter.format(date, this.DATE_FORMAT),
        ),
      ],
    });
  }

  onDatepickerOpened(): void {
    const componentRef = this.picker['_componentRef'] as ComponentRef<
      MatDatepicker<Date>
    >;
    const native = (
      componentRef?.instance as unknown as {
        _elementRef: ElementRef<HTMLElement>;
      }
    )?._elementRef.nativeElement;
    const datesWrapper = document.createElement('div');
    datesWrapper.classList.add('repeat-order-dates');
    native?.lastElementChild.insertAdjacentElement('afterend', datesWrapper);
    this.datesWrapperElem = datesWrapper;
    this.initDates();
  }

  initDates(): void {
    if (this.repeatOrder?.repeat_dates?.length) {
      this.selectedDates = [];
      this.repeatOrder.repeat_dates.forEach((dateString) => {
        const date = this.dateAdapter.parse(dateString, this.DATE_FORMAT);
        this.addDate(date);
        this.selectedDates.push(date);
      });
    }
  }

  addDate(date: Date): void {
    const formattedDate = this.dateAdapter.format(date, 'dd MMMM yyyy');
    const elem = document.createElement('div');
    elem.classList.add('repeat-order-selected-date');
    elem.innerText = formattedDate;
    this.datesWrapperElem?.appendChild(elem);
    this.dateElementsMap[date.getDate()] = elem;
  }

  removeDate(date: Date): void {
    const elem = this.dateElementsMap[date.getDate()];
    if (elem) {
      elem.remove();
      delete this.dateElementsMap[date.getDate()];
    }
  }

  dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return ['selected'];
    }
    return [];
  };

  private _findDate(date: Date): number {
    return this.selectedDates.map((m) => +m).indexOf(+date);
  }
}
