import { Component, input, output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoPipe } from '@jsverse/transloco';

import { FieldWithErrorsComponent } from '../../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../../shared/components/form-with-errors/form-with-errors.component';

@Component({
  selector: 'win-reset',
  templateUrl: './reset.component.html',
  styleUrls: [
    '../../../../shared/styles/authentication.scss',
    './reset.component.scss',
  ],
  imports: [
    FieldWithErrorsComponent,
    FormWithErrorsComponent,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class ResetComponent {
  readonly spinnerState = input(false);

  readonly resetEmail = output<{
    email: string;
  }>();

  resetPasswordForm = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
    }),
  });

  onSubmit(): void {
    // Mark all fields as touched to trigger validation
    Object.keys(this.resetPasswordForm.controls).forEach((key) => {
      const control = this.resetPasswordForm.get(key);
      control?.markAsTouched();
    });

    if (!this.resetPasswordForm.valid) return;

    this.resetEmail.emit({
      email: this.resetPasswordForm.value.email,
    });
  }
}
