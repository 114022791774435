export enum ROUTER_BACK_NAVIGATION {
  accesses = 'navigation.back-to-accesses',
  consumers = 'navigation.back-to-consumers',
  orders = 'navigation.back-to-orders',
}

export const MENU_SEARCH_CLASS = 'search-hidden';
export const DATA_ALLERGEN_CLASS = 'menu-item-disable';

export const SimpleAuthenticationIds = [
  `auth_rfid`,
  `auth_external_id`,
  `auth_uuid`,
] as const;

export const emailVerificationErrorFields = ['disposable'];
export const urlRegexPattern =
  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]+)*\/?(\?[^?#]+)?(#.+)?$/;

export const dietOrdering = [
  'order,de',
  'order,en',
  'order,es',
  'order,fr',
  'order,it',
  'de',
  'en',
  'es',
  'fr',
  'it',
] as const;
