import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { Course } from '../../shared/models/menus';
import * as CoursesActions from './courses.actions';

interface CoursesState {
  items: EntityState<Course>;
  loading: boolean;
  selectedCourse: Course;
}

const adapter: EntityAdapter<Course> = createEntityAdapter<Course>();

const initialState: CoursesState = {
  items: adapter.getInitialState({}),
  loading: false,
  selectedCourse: null,
};

const reducer = createReducer<CoursesState>(
  initialState,
  on(CoursesActions.addCreatedCourse, (state, { payload }) => ({
    ...state,
    items: adapter.setAll(
      [payload, ...adapter.getSelectors().selectAll(state.items)],
      state?.items,
    ),
  })),
  on(CoursesActions.setCourses, (state, { items }) => ({
    ...state,
    items: adapter.setAll(items.results, state.items),
  })),
  on(CoursesActions.setCoursesDataLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(CoursesActions.removeDeletedCourse, (state, { courseId }) => ({
    ...state,
    items: adapter.removeOne(courseId, state.items),
  })),
  on(CoursesActions.setSelectedCourse, (state, { course }) => ({
    ...state,
    selectedCourse: course,
  })),

  on(CoursesActions.setUpdatedCourse, (state, { course, courseId }) => ({
    ...state,
    items: adapter.updateOne({ id: courseId, changes: course }, state?.items),
  })),
);

export const coursesFeature = createFeature({
  name: 'courses',
  reducer,
  extraSelectors: ({ selectItems }) => ({
    selectCoursesEntities: createSelector(
      selectItems,
      adapter.getSelectors().selectAll,
    ),
  }),
});
