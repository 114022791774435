import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { fetchRepeatOrders } from 'src/app/store/orders/orders.actions';
import {
  selectQueryParams,
  selectRouteParams,
} from 'src/app/store/router/router.selectors';
import {
  ManagableAccess,
  isManagableItem,
} from 'src/app/shared/models/consumers';

import {
  getFormattedDate,
  getMenusParamsFromQueryParams,
  getParamsFromManagableItem,
  isObjectEmpty,
  removeUndefinedAndNull,
} from '../../shared/utils.functions';
import { MenusServiceBuilder } from '../../shared/services/menus/menus.builder';
import { UtilsService } from '../../shared/services/utils.service';
import {
  handleHttpError,
  showSnackbarMessage,
} from './../global/global.actions';
import { clearOrdersPage, setManagableItem } from './../orders/orders.actions';
import * as MenuActions from './menus.actions';
import { Menu } from 'src/app/shared/models/menus';
import { User } from 'src/app/shared/models/user';
import { menusFeature } from './menus.state';
import { ordersFeature } from '../orders/orders.state';
import { usersFeature } from '../user/user.state';

@Injectable()
export class MenusEffects {
  private readonly actions$ = inject(Actions);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly menusServiceBuilder = inject(MenusServiceBuilder);
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly transloco = inject(TranslocoService);
  private readonly utilsService = inject(UtilsService);

  onNavigatedToMenusPage = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter(
        (action: RouterAction<RouterStateSnapshot>) =>
          action?.payload?.event?.url === `/menus`,
      ),
      switchMap(() => [clearOrdersPage()]),
      catchError(() => EMPTY),
    ),
  );

  menuSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.menuSelected),
      switchMap(({ menu, item, date }) => [
        MenuActions.setSelectedDate({ date }),
        MenuActions.setCurrentMenuAndRedirect({
          menu,
          item,
          date,
        }),
      ]),
      catchError(() => EMPTY),
    ),
  );

  setCurrentMenuAndRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.setCurrentMenuAndRedirect),
      switchMap(({ menu, item, date }) => {
        // dated menus have the has_orders boolean which indicates if orders exist for this menu/date
        // dateless menus have a list_orders array which contains the dates for which orders exist for this menu
        const complete =
          menu?.show_date || !date
            ? menu?.has_orders
            : menu?.list_orders.includes(date);
        return [
          MenuActions.setCurrentMenu({ menu, date }),
          setManagableItem({ item }),
          MenuActions.redirectToOrders({ complete }),
        ];
      }),
      catchError(() => EMPTY),
    ),
  );

  redirectToOrders$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MenuActions.redirectToOrders),
        withLatestFrom(
          this.store.select(menusFeature.selectCurrentMenu),
          this.store.select(selectRouteParams),
          this.store.select(ordersFeature.selectRouterExtras),
          this.store.select(selectQueryParams),
          this.store.select(menusFeature.selectSelectedDate),
          this.store.select(usersFeature.selectSimpleConsumer),
        ),
        switchMap(
          ([
            { complete },
            currentMenu,
            params,
            item,
            queryParams,
            pickedDate,
            simpleConsumer,
          ]) => {
            const menuId =
              currentMenu?.identifier || (params?.menuId as string);
            let date =
              currentMenu?.date || (params?.date as string) || pickedDate;
            if (!date) {
              console.error(`Date isn't set, using today's date`);
              date = getFormattedDate();
            }
            const navigate = [`orders`, menuId, date];
            const extraData = { state: item, queryParams: {} };
            if (
              complete ||
              (complete === undefined &&
                currentMenu?.list_orders?.includes(date))
            ) {
              navigate.push(`complete`);
            }
            if (item && isManagableItem(item)) {
              Object.assign(extraData, {
                queryParams: { ...getParamsFromManagableItem(item) },
              });
            } else if (queryParams && !isObjectEmpty(queryParams)) {
              Object.assign(extraData.queryParams, { ...queryParams });
            }
            if (extraData?.queryParams && queryParams?.back) {
              extraData.queryParams[`back`] = queryParams.back as string;
            }
            if (simpleConsumer) {
              extraData['queryParamsHandling'] = 'preserve';
            }
            this.router.navigate(navigate, extraData);
            return EMPTY;
          },
        ),
      ),
    { dispatch: false },
  );

  fetchMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.fetchMenu),
      switchMap(
        ({
          params: { menu, date, created_by, consumer },
          fetchRepeatOrders: repeat,
        }) =>
          this.menusServiceBuilder
            .getService()
            .fetchMenu({
              menu,
              date,
              ...removeUndefinedAndNull({ created_by, consumer }),
            })
            .pipe(
              mergeMap((menus) => [
                ...(menus?.results?.length && menus?.results?.[0].date
                  ? []
                  : [MenuActions.setSelectedDate({ date })]),
                MenuActions.setCurrentMenu({ menu: menus?.results?.[0] }),
                ...(repeat || menus?.results?.[0]?.repeated_orders_consumers
                  ? [
                      fetchRepeatOrders({
                        menu_name:
                          menus?.results?.length &&
                          (menus?.results?.[0].type ||
                            !menus?.results?.[0].date)
                            ? menus?.results?.[0].name
                            : '',
                        created_by,
                        consumer,
                        manage: true,
                      }),
                    ]
                  : []),
              ]),
              catchError((error: unknown) => [handleHttpError({ error })]),
            ),
      ),
    ),
  );

  fetchMenus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.fetchMenus),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([{ params }, user]) =>
        this.menusServiceBuilder
          .getService()
          .fetchMenus(params)
          .pipe(
            mergeMap((menus) => {
              const processMenu = (menu: Menu) => {
                if (
                  !menu?.date ||
                  (menu.order_by_days_before === null && !user?.status) ||
                  user?.status?.orderby_days_offset === null
                ) {
                  return menu;
                }

                // Calculate deadline date
                const menuDate = new Date(menu.date);
                const dateToCompare = new Date();
                const daysOffset =
                  menu.order_by_days_before ||
                  user?.status?.orderby_days_offset;
                menuDate.setDate(menuDate.getDate() - daysOffset);

                // Check if deadline has passed
                const showBadgePast = menuDate < dateToCompare;
                const showBadge =
                  menuDate.toDateString() === dateToCompare.toDateString();

                // Calculate if time has passed for today's deadline
                const todayPastTime =
                  showBadge && user?.status?.orderby_time
                    ? isTimePassedForToday(
                        menu.order_by_time || user.status.orderby_time,
                      )
                    : false;

                // Set badge properties
                menu.showBadge = getBadgeConfig(
                  showBadge,
                  showBadgePast,
                  todayPastTime,
                  menu,
                  user,
                );

                return menu;
              };

              const isTimePassedForToday = (timeString: string) => {
                const now = new Date();
                const [hours, minutes] = timeString.split(':');
                const deadline = new Date(now);
                deadline.setHours(Number(hours), Number(minutes), 0);
                return now.getTime() > deadline.getTime();
              };

              const getBadgeConfig = (
                showBadge: boolean,
                showBadgePast: boolean,
                todayPastTime: boolean,
                menu: Menu,
                user: User,
              ) => {
                if (showBadge) {
                  const timeString =
                    menu.order_by_time || user?.status?.orderby_time;
                  return {
                    today: todayPastTime,
                    time: timeString?.substring(0, timeString.length - 3),
                  };
                }
                if (showBadgePast) {
                  return { time: null };
                }
                return undefined;
              };

              const newMenus = menus?.results?.map(processMenu);
              return [MenuActions.setMenus({ menus: newMenus })];
            }),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  patchCheckoutDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.patchCheckoutDate),
      switchMap(({ payload: { data, url } }) =>
        this.utilsService
          .patch<Partial<ManagableAccess>, ManagableAccess>(url, data, {
            manage: true,
          })
          .pipe(
            mergeMap((result) => {
              const params = getMenusParamsFromQueryParams(
                this.activatedRoute.snapshot.queryParamMap,
              );
              return [
                showSnackbarMessage({
                  message: this.transloco.translate(this.MODIFICATION_SAVED),
                  button: this.transloco.translate(this.GOT_IT),
                }),
                setManagableItem({ item: result || undefined }),
                MenuActions.fetchMenus({
                  params: isObjectEmpty(params) ? { own: true } : params,
                }),
              ];
            }),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  readonly GOT_IT = 'manage-accesses.invite-user.got-it';
  readonly MODIFICATION_SAVED =
    'manage-accesses.invite-user.modification-saved';
}
