import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  input,
  output,
  viewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import {
  NgxOtpInputComponent,
  NgxOtpInputComponentOptions,
} from 'ngx-otp-input';
import { FormWithErrorsComponent } from 'src/app/shared/components/form-with-errors/form-with-errors.component';
import { NON_FIELD_ERRORS } from 'src/app/shared/directives/server-form-error.directive';
import { FormErrors } from 'src/app/shared/models/globals';

@Component({
  selector: 'win-unauthenticated-login',
  templateUrl: './unauthenticated-login.component.html',
  styleUrls: ['./unauthenticated-login.component.scss'],
  imports: [
    MatCardModule,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    NgxOtpInputComponent,
    MatProgressSpinnerModule,
    TranslocoPipe,
  ],
})
export class UnauthenticatedLoginComponent
  implements OnChanges, OnDestroy, OnInit
{
  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly cdr = inject(ChangeDetectorRef);

  readonly formErrors = input.required<FormErrors>();
  readonly spinnerState = input.required<boolean>();
  readonly aggregatedOrderFeature = input.required<boolean>();
  readonly unauthInvalidUrlMessage = input.required<boolean>();

  readonly clearFormErrors = output<{ payload: string }>();
  readonly retrieveUuid = output<{
    consumerName: { first_name: string; last_name: string } | { name: string };
    key: string;
    roomNo: string;
  }>();
  readonly validateKey = output<string>();

  consumerForm = new FormGroup({
    first_name: new FormControl<string>(null),
    last_name: new FormControl<string>(null),
    name: new FormControl<string>(null),
  });
  readonly consumerFormId = 'unauthenticated-form';
  private errorClearTimeout: number | null = null;
  key: string;
  otpInputConfig: NgxOtpInputComponentOptions = {
    otpLength: 3,
    inputMode: 'text',
    regexp: /^\p{L}+$/u,
  };
  roomNo: string;
  showLastName = false;

  readonly codeInputFirstName =
    viewChild<NgxOtpInputComponent>('firstNameCodeInput');
  readonly codeInputLastName =
    viewChild<NgxOtpInputComponent>('lastNameCodeInput');
  readonly codeInputName = viewChild<NgxOtpInputComponent>('nameCodeInput');

  constructor() {
    this.key = this.activatedRouter.snapshot.queryParams['key'] as string;
    this.roomNo = this.activatedRouter.snapshot.queryParams[
      'auth_room'
    ] as string;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const formErrors = this.formErrors();
    if (
      changes.formErrors &&
      formErrors?.[NON_FIELD_ERRORS] &&
      this.consumerForm
    ) {
      // Clear any existing timeout
      if (this.errorClearTimeout !== null) {
        clearTimeout(this.errorClearTimeout);
        this.errorClearTimeout = null;
      }

      // Reset the form
      this.consumerForm.reset({}, { emitEvent: false });
      this.consumerForm.markAsDirty();
      this.consumerForm.setErrors({
        error: formErrors[NON_FIELD_ERRORS][0].message,
      });

      // Reset input fields based on feature flag
      this.resetInputFields();

      // Clear errors after 10 seconds (just like in the original code)
      this.errorClearTimeout = window.setTimeout(() => {
        this.consumerForm.updateValueAndValidity();
        this.clearFormErrors.emit({ payload: NON_FIELD_ERRORS });
      }, 10000);
    }
  }

  ngOnInit(): void {
    this.validateKey.emit(this.key);
  }

  private resetInputFields(): void {
    const aggregatedOrders = this.aggregatedOrderFeature();
    if (aggregatedOrders) {
      const codeInputName = this.codeInputName();
      if (codeInputName) codeInputName.reset();
    } else {
      const codeInputFirstName = this.codeInputFirstName();
      if (codeInputFirstName) codeInputFirstName.reset();
      const codeInputLastName = this.codeInputLastName();
      if (codeInputLastName) codeInputLastName.reset();
      setTimeout(() => {
        this.showLastName = false;
      });
    }
  }

  onCodeCompleted(code: string, name: string): void {
    if (name === 'first_name') {
      this.consumerForm.controls.first_name.setValue(code);
      this.codeInputFirstName().disabled = true;
      setTimeout(() => {
        this.codeInputFirstName().disabled = false;
        this.showLastName = true;
        this.cdr.detectChanges();
      }, 350);
    }
    if (name === 'last_name') {
      this.consumerForm.controls.last_name.setValue(code);
    }
    if (
      this.consumerForm.controls.first_name.value?.length &&
      this.consumerForm.controls.last_name.value?.length
    ) {
      const consumerName = {
        first_name: this.consumerForm.controls.first_name.value,
        last_name: this.consumerForm.controls.last_name.value,
      };
      this.retrieveUuid.emit({
        consumerName: consumerName,
        key: this.key,
        roomNo: this.roomNo,
      });
    }
  }

  onNameCodeCompleted(code: string): void {
    this.consumerForm.controls.name.setValue(code);
    const consumerName = {
      name: this.consumerForm.controls.name.value,
    };
    this.retrieveUuid.emit({
      consumerName: consumerName,
      key: this.key,
      roomNo: this.roomNo,
    });
  }

  ngOnDestroy(): void {
    if (this.errorClearTimeout !== null) {
      clearTimeout(this.errorClearTimeout);
    }
  }
}
