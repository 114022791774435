<mat-card
  appearance="outlined"
  class="mat-body card mat-elevation-z2"
  data-cy="password-confirm-form"
>
  <win-form-with-errors
    class="form"
    [formGroup]="passwordsForm"
    formId="confirm-new-password"
  >
    <div class="fields">
      <win-field-with-errors
        class="field password-field"
        icon="lock"
        [type]="showPassword1() ? 'text' : 'password'"
        [autocomplete]="'new-password'"
        [label]="'registration.password1.placeholder' | transloco"
        translationKey="registration.password1.errors"
        formControlName="new_password1"
        [required]="true"
      >
        <button
          class="suffixTemplate"
          type="button"
          mat-icon-button
          [attr.aria-label]="'shared.toggle-password-visibility' | transloco"
          (click)="togglePassword1Visibility()"
          data-cy="toggle-password1-visibility"
        >
          <mat-icon>{{
            showPassword1() ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
      </win-field-with-errors>
      <win-field-with-errors
        class="field password-field"
        icon="lock"
        [type]="showPassword2() ? 'text' : 'password'"
        [autocomplete]="'new-password'"
        [label]="'registration.password2.placeholder' | transloco"
        formControlName="new_password2"
        translationKey="registration.password2.errors"
        [required]="true"
      >
        <button
          class="suffixTemplate"
          type="button"
          mat-icon-button
          [attr.aria-label]="'shared.toggle-password-visibility' | transloco"
          (click)="togglePassword2Visibility()"
          data-cy="toggle-password2-visibility"
        >
          <mat-icon>{{
            showPassword2() ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
      </win-field-with-errors>
    </div>
    <button
      class="submit-button"
      mat-flat-button
      color="primary"
      type="submit"
      data-cy="submit-reset"
      [disabled]="spinnerState()"
      (click)="onSubmit()"
    >
      {{ 'password-reset.reset' | transloco }}
      @if (spinnerState()) {
        <span class="spinner-container">
          <mat-spinner
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="24"
          ></mat-spinner>
        </span>
      }
    </button>
  </win-form-with-errors>
</mat-card>
