<mat-card appearance="outlined" class="mat-body card mat-elevation-z2">
  <win-form-with-errors
    class="form"
    [formGroup]="resetPasswordForm"
    formId="reset-password"
  >
    <div class="fields">
      <win-field-with-errors
        class="field"
        icon="email"
        type="email"
        [autocomplete]="'email'"
        [label]="'registration.email.placeholder' | transloco"
        translationKey="registration.email.errors"
        formControlName="email"
        [required]="true"
      ></win-field-with-errors>
    </div>
    <button
      class="submit-button"
      mat-flat-button
      color="primary"
      type="submit"
      data-cy="submit-reset"
      (click)="onSubmit()"
      [disabled]="spinnerState()"
    >
      {{ 'password-reset.reset' | transloco }}
      @if (spinnerState()) {
        <span class="spinner-container">
          <mat-spinner
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="24"
          ></mat-spinner>
        </span>
      }
    </button>
  </win-form-with-errors>
</mat-card>
