import { Component, input, output, signal } from '@angular/core';
import {
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoPipe } from '@jsverse/transloco';
import { FieldWithErrorsComponent } from 'src/app/shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from 'src/app/shared/components/form-with-errors/form-with-errors.component';
import { LoginForm } from 'src/app/shared/models/authentication';

@Component({
  selector: 'win-simple-login',
  templateUrl: './simple-login.component.html',
  styleUrls: [
    '../../../../shared/styles/authentication.scss',
    './simple-login.component.scss',
  ],
  imports: [
    MatCardModule,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    RouterLink,
    TranslocoPipe,
  ],
})
export class SimpleLoginComponent {
  readonly login = output<LoginForm>();
  readonly spinnerState = input(false);

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
  });
  showPassword = signal(false);

  togglePasswordVisibility(): void {
    this.showPassword.update((current) => !current);
  }

  onSubmit(): void {
    // Mark all fields as touched to trigger validation
    Object.keys(this.loginForm.controls).forEach((key) => {
      const control = this.loginForm.get(key);
      control?.markAsTouched();
    });

    if (!this.loginForm.valid) return;

    this.login.emit({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    });
  }
}
