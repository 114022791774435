import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslocoPipe } from '@jsverse/transloco';

import { LoginForm } from 'src/app/shared/models/authentication';
import {
  login,
  retrieveUuid,
  validateKey,
} from 'src/app/store/authentication/authentication.actions';
import { authenticationFeature } from 'src/app/store/authentication/authentication.state';
import { SimpleLoginComponent } from './simple-login/simple-login.component';
import { UnauthenticatedLoginComponent } from './unauthenticated-login/unauthenticated-login.component';
import { globalFeature } from 'src/app/store/global/global.state';
import { clearFormErrors } from 'src/app/store/global/global.actions';

@Component({
  selector: 'win-login',
  templateUrl: './login.container.html',
  styleUrls: ['./login.container.scss'],
  imports: [
    UnauthenticatedLoginComponent,
    SimpleLoginComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class LoginContainer implements OnInit {
  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly store = inject(Store);

  spinnerState$ = this.store.pipe(
    select(authenticationFeature.selectLoginSpinner),
  );
  unauthAggregatedOrderTakingEnabled$ = this.store.pipe(
    select(authenticationFeature.selectUnauthAggregatedOrderFeature),
  );
  unauthFormErrors$ = this.store.pipe(select(globalFeature.selectFormErrors));
  unauthInvalidUrlMessage$ = this.store.pipe(
    select(authenticationFeature.selectUnauthInvalidUrlMessage),
  );
  unauthSpinnerState$ = this.store.pipe(
    select(authenticationFeature.selectUnauthSpinner),
  );

  unauthenticatedOrder = false;

  @HostBinding('class') get HeadingClass() {
    if (this.unauthenticatedOrder) {
      return 'background';
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    if (
      this.activatedRouter.snapshot.queryParams.key &&
      this.activatedRouter.snapshot.queryParams.auth_room
    ) {
      this.unauthenticatedOrder = true;
      this.cdr.detectChanges();
    }
  }

  onLogin(data: LoginForm): void {
    this.store.dispatch(login({ ...data, formId: 'simple-login' }));
  }

  onClearFormErrors({ payload }: { payload: string }): void {
    this.store.dispatch(clearFormErrors({ payload }));
  }

  onRetrieveUuid(data: {
    consumerName: { first_name: string; last_name: string } | { name: string };
    key: string;
    roomNo: string;
  }): void {
    this.store.dispatch(retrieveUuid(data));
  }

  onValidateKey(key: string): void {
    this.store.dispatch(validateKey({ key }));
  }
}
