<mat-card appearance="outlined" class="mat-body card mat-elevation-z2">
  <win-form-with-errors
    class="form"
    [formGroup]="loginForm"
    formId="simple-login"
  >
    <div class="fields">
      <win-field-with-errors
        class="field"
        icon="email"
        type="email"
        [autocomplete]="'email'"
        [label]="'registration.email.placeholder' | transloco"
        translationKey="registration.email.errors"
        formControlName="email"
        [required]="true"
      ></win-field-with-errors>
      <win-field-with-errors
        class="field password-field"
        icon="lock"
        [type]="showPassword() ? 'text' : 'password'"
        [autocomplete]="'current-password'"
        [label]="'registration.password1.placeholder' | transloco"
        formControlName="password"
        translationKey="registration.password1.errors"
        [required]="true"
      >
        <button
          class="suffixTemplate"
          type="button"
          mat-icon-button
          [attr.aria-label]="'shared.toggle-password-visibility' | transloco"
          (click)="togglePasswordVisibility()"
          data-cy="toggle-password-visibility"
        >
          <mat-icon>{{
            showPassword() ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
      </win-field-with-errors>
    </div>
    <button
      class="submit-button"
      data-cy="submit-login"
      mat-flat-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
      [disabled]="spinnerState()"
    >
      {{ 'login.login-button' | transloco }}
      @if (spinnerState()) {
        <span class="spinner-container">
          <mat-spinner
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="24"
          ></mat-spinner>
        </span>
      }
    </button>
  </win-form-with-errors>
</mat-card>

<div class="links mat-body">
  <a
    data-cy="remind-password-link"
    routerLink="/reset-password"
    [attr.aria-label]="'app.links.remind-password-aria' | transloco"
  >
    {{ 'app.links.remind-password' | transloco }}
  </a>
  <a
    data-cy="register-link"
    routerLink="/registration"
    [attr.aria-label]="'app.links.register-aria' | transloco"
  >
    {{ 'app.links.register' | transloco }}
  </a>
</div>
